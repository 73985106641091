.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiButtonBase-root {
  color: #bbb !important;
}

.MuiTable-root {
  border-collapse: initial !important;
}

.MuiTableCell-root {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid #777 !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color:#282a36;
}

.movie-group {
  width: 48%;
}

.movie {
  margin: 20px;
}

.movie .title {
  font-size: 20px;
  color: white;
  text-align: left;
  margin-bottom:15px;
  margin-left: 15px;
  font-weight: bold;
}

.format-icon {
  width: 35px;
  height: 35px;
  margin: 0px 3px;
}

/* .MuiIconButton-root {
  color: #f8f8f8 !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #44475a !important;
}

.MuiTablePagination-root {
  color: #f8f8f8 !important;
} */

.MuiTableCell-body {
  background-color: #282a36 !important;
}

.movie-row {
  text-align: left;
  width: 100%;
  display: flex;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 20px;
  background-color: #44475a;
  margin-bottom: 10px;
}

.movie-info {
  flex-grow: 1;
  padding-left:5px;
  margin-right:20px;
}

.add-button {
  color: #282a36;
  font-size: 13px; 
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  display: block;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.1s linear;
  -ms-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.available {
  background-color: #50fa7b;
  
}

.available:hover {
  background-color: #40ce63;
}

.available:active {
  background-color: #32a14e;
}

.unavailable {
  background-color: #ff5555;
}

.unavailable:hover {
  background-color: #cf4444;
}

.unavailable:active {
  background-color: #aa3636;
}

/* 
.available {
  background-color: #50fa7b;
  
}

.available:hover {
  background-color: #40ce63;
}

.available:active {
  background-color: #32a14e;
}

.unavailable {
  background-color: #ff5555;
}

.unavailable:hover {
  background-color: #cf4444;
}

.unavailable:active {
  background-color: #aa3636;
} */

.movie-details {
  font-size: 14px;
}

.movie-title {
  font-size: 25px;
  margin-bottom: 10px;
}

.movie-year {
  font-weight: 300;
  font-size:17px;
  color: #a4a6ac;
}

.movie-thumbnail {
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.navbar {
  padding: 0px 20px;
  text-align: center;
  font-size: 30px;
}

.navbar-divider {
  height: 2px;
  background-color: #9b59b6;
}
